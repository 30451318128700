

































































































enum CurrentView {
  Active,
  Catalog,
  Setting,
}

import ModuleBuilder from '@/includes/logic/Modules/Builders/ModuleBuilder'
import BaseModule from '@/includes/logic/Modules/models/BaseModule'
import ModuleActionsMixin from '@/includes/logic/Modules/mixins/ModuleActionsMixin'
import { ModuleManagerState } from '@/includes/logic/Modules/ModulesManager'
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'
import CurrentModule from '../components/CurrentModule.vue'
import ModuleCard from '../components/ModuleCard.vue'
import ActiveModulesList from '../components/ActiveModulesList.vue'
import { ModuleTypesEnum } from '@/includes/logic/Modules/types/types'
import NewUserDeprecatedTriggersHandlerModule
  from "@/includes/logic/Modules/models/modules/Greeting/NewUserDeprecatedTriggersHandlerModule/NewUserDeprecatedTriggersHandlerModule";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'

import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    CenteredColumnLayout,
    ActiveModulesList,
    PageTitle,
    ModuleCard,
    EmptyData,
    CurrentModule
  },
  data() {
    return {
      CurrentView
    }
  }
})
export default class ModulesScreensWrapper extends Mixins<ModuleActionsMixin, TempCurrentModuleMixin>(ModuleActionsMixin, TempCurrentModuleMixin) {
  @Prop() moduleTypesList!: Array<ModuleTypesEnum>

  currentView: CurrentView = CurrentView.Active

  @Emit()
  currentViewUpdate(view: CurrentView) {
    return view
  }

  @Watch('currentView')
  onCurrentViewChange(type:CurrentView) {
    if (type !== CurrentView.Setting) {
      this.$nextTick(() => {
        this.$baseTemplate.saveButton.hide()
      })
    }

    this.currentViewUpdate(type)
  }

  get activeModuleBuilders() {
    if (ModuleManagerState) {
      return ModuleManagerState.getActiveModules()
        .filter(b => this.moduleTypesList.includes(b.type))
        .reduce(ModuleManagerState.makeBuildersListReducer, [])
    }

    return []
  }

  get inactiveModuleBuilders() {
    if (ModuleManagerState) {
      const allModuleBuilders = ModuleManagerState.allModuleBuilders()
      const activeModules = ModuleManagerState.getActiveModules()

      return allModuleBuilders.filter(b => {
        return this.moduleTypesList.includes(b.model.type)
          && !activeModules.some(m => m.type === b.model.type)
          && !(b.model instanceof NewUserDeprecatedTriggersHandlerModule)
      })

    }

    return []
  }

  onModuleSetting(module: BaseModule) {
    this.moduleSetting(module)
      .then(() => {
        this.currentView = CurrentView.Setting
      })
  }

  onAddModule(module: ModuleBuilder) {
    this.addModule(module, this.$store.getters.chatId)
      .then((moduleBuilder) => {
        if (moduleBuilder) {
          if (moduleBuilder.hasEmptyParams()) {
            ModuleManagerState?.setCurrentModule(moduleBuilder.model)

            this.saveModule()
              .then(() => {
                this.currentView = CurrentView.Active
                ModuleManagerState?.clearCurrentModule()
              })
          } else {
            this.currentView = CurrentView.Setting
          }
        }
      })
  }

  onBack() {
    if (ModuleManagerState && ModuleManagerState.isCurrentModuleChanged && !ModuleManagerState.currentModule!.hasLimits() && ModuleManagerState.currentModule!.isSaveable()) {
      const onOk = () => this.saveModule()
        .then(() => {
          this.currentView = CurrentView.Active
          //
          if (ModuleManagerState) {
            ModuleManagerState.clearCurrentModule()
          }
        })

      const onCancel = () => {
        this.currentView = CurrentView.Active

        if (ModuleManagerState) {
          ModuleManagerState.clearCurrentModule()
        }
      }

      this.saveModuleSettingsConfirm(onOk, onCancel)
    } else {
      this.currentView = CurrentView.Active

      if (ModuleManagerState) {
        ModuleManagerState.clearCurrentModule()
      }
    }
  }

  checkHash() {
    const hash = this.$route.hash
    let found = false

    if (hash) {
      const hasHashInParams = function<T extends Record<string, any>>(params: T) {
        return Object.keys(params).some(k => k === hash.substring(1))
      }

      for (const moduleBuilder of this.activeModuleBuilders) {
        if (moduleBuilder.model.params) {
          if (hasHashInParams(moduleBuilder.model.params)) {
            this.onModuleSetting(moduleBuilder.model)

            found = true

            break
          }
        }
      }

      if (found) {
        return
      }

      for (const inactiveActivityModuleBuilder of this.inactiveModuleBuilders) {
        if (inactiveActivityModuleBuilder.model.params) {
          if (hasHashInParams(inactiveActivityModuleBuilder.model.params)) {
            this.onAddModule(inactiveActivityModuleBuilder)

            break
          }
        }
      }
    }
  }

  created() {
    this.checkHash()
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}

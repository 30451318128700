import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import NewUserDeprecatedTriggersHandlerModule
  from "../models/modules/Greeting/NewUserDeprecatedTriggersHandlerModule/NewUserDeprecatedTriggersHandlerModule";
import { CreateTypeEnum, ModuleGroupEnum } from "@/includes/logic/Modules/types/types";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import ModuleActionsMixin from "@/includes/logic/Modules/mixins/ModuleActionsMixin";
import { stringIsModuleGroupEnum } from "@/includes/logic/Modules/ModulesGroupsUtil";
import { isChatManager } from "@/includes/logic/Modules/types/predicates";
import ModulesRoutesHelperMixin from "@/includes/logic/Modules/mixins/ModulesRoutesHelperMixin";
import ModulesApi from "@/includes/Api/Modules.api";

import { Component, Mixins, Watch } from "vue-property-decorator";
import { Modal } from "ant-design-vue";
import { debounce } from "lodash";

@Component
export default class ModulesListMixin extends Mixins(ModuleActionsMixin, ModulesRoutesHelperMixin) {
  currentGroup: ModuleGroupEnum | null = null

  searchQuery = ''

  @Watch('searchQuery')
  onSearchQueryChange = debounce((value) => {
    if (this.isChatManagerVm) {
      ModulesApi.saveSearchContent(this.$store.getters.chatId, value)
          .catch(errorNotification)
    }
  }, 1000)

  get allModules(): Array<ModuleBuilder> {
    if (ModuleManagerState) {
      return ModuleManagerState.allModuleBuilders()
          .filter(m => !(m.model instanceof NewUserDeprecatedTriggersHandlerModule))
    }

    return []
  }

  get filteredAllModules() {
    const moduleTypeExistFilter = (m: ModuleBuilder) => {
      const module = ModuleManagerState!.getModuleByCriteria('type', m.model.type)

      return this.isChatManagerVm ? module && module.config.create_type === CreateTypeEnum.Network || !module : !module
    }

    const groupFilter = (m: ModuleBuilder) => m.group === this.currentGroup

    const hasActiveUltimate = this.$store.getters.isChatLicenseExists && this.$store.getters.getHaveLicense

    if (this.currentGroup) {
      // if (hasActiveUltimate) {
      //   return this.modules.filter(groupFilter)
      // } else {
      return this.allModules
          .filter(moduleTypeExistFilter)
          .filter(groupFilter)
      // }
    } else {
      // if (hasActiveUltimate) {
      //   return this.modules
      // } else {
      return this.allModules
          .filter(moduleTypeExistFilter)
      // }
    }
  }

  queriedModules(modulesToQuery?: Array<ModuleBuilder>) {
    let modules = modulesToQuery ?? this.filteredAllModules

    const trimmedQuery = this.searchQuery.toLowerCase().trim()

    if (trimmedQuery) {
      modules = modules.filter(m =>
          this.$t(m.model.titleKey).toString().toLowerCase().trim().includes(trimmedQuery)
          || m.aliases && m.aliases.some(a => a.trim().toLowerCase().includes(trimmedQuery))
      )
    }

    if (this.currentGroup) {
      modules = modules.filter(m => m.group === this.currentGroup)
    }

    return modules
  }

  saveModule() {
    if (ModuleManagerState && ModuleManagerState.currentModule) {
      return ModuleManagerState.saveModule(ModuleManagerState.currentModule)
    }

    return Promise.resolve()
  }

  //todo
  get isChatManagerVm() {
    return isChatManager(ModuleManagerState)
  }

  get chatId() {
    if (this.isChatManagerVm) {
      return this.$store.getters.isChatSet && this.$store.getters.chatId
    } else {
      const network = this.$store.state.networksState.currentNetwork

      return network && network.chats.length && network.chats[0]
    }
  }

  moduleLoading: ModuleBuilder['model']['guid'] | null = null

  onAddModule(moduleBuilder: ModuleBuilder) {
    if (!this.moduleLoading) {
      this.moduleLoading = moduleBuilder.model.guid

      this.addModule(moduleBuilder, this.chatId)
          .then((builder) => {
            if (builder) {
              if (builder.hasEmptyParams()) {
                ModuleManagerState?.setCurrentModule(builder.model)

                this.saveModule()
                    .then((res) => {
                      if (res) {
                        successNotification(this.$t('module_notification_module_add').toString())

                        ModuleManagerState?.clearCurrentModule()
                        Modal.destroyAll()

                        this.moduleLoading = null
                        this.gotoActiveModules()
                      }
                    })
              } else {
                this.moduleLoading = null

                this.gotoCurrentModule(builder.model.type)
              }
            } else {
              this.moduleLoading = null
            }
          })
    }
  }

  created() {
    const query = this.$route.params?.tab?.toString()

    if (stringIsModuleGroupEnum(query)) {
      this.currentGroup = query as ModuleGroupEnum
    }
  }
}
































































































































































































































































































































































import ActiveModulesList from '@/components/Modules/components/ActiveModulesList.vue'
import { InputSetups } from '@/mixins/input-setups'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import CurrentModule from '@/components/Modules/components/CurrentModule.vue'
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'
import { ModuleTypesEnum } from '@/includes/logic/Modules/types/types'
import ModuleCard from '@/components/Modules/components/ModuleCard.vue'
import ModulesScreensWrapper from '@/components/Modules/modules/ModulesScreensWrapper.vue'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'

enum CurrentView {
  Active,
  Catalog,
  Setting,
}

@Component({
  'components': {
    ModulesScreensWrapper,
    CenteredColumnLayout,
    PageTitle,
    ConfigField,
    NotAvailableOptionsOverlay,
    //
    ActiveModulesList,
    CurrentModule,
    ModuleCard,
  },
  data() {
    return {
      getTariffTranslationKey,
      MediaEditorMode,
      StepUnit
    }
  }
})
export default class Extra extends Mixins(UseFields, InputSetups, TariffsTagsHelper, TempCurrentModuleMixin) {
  // autoAttackModeInitValue: number | null = null;

  currentView = 0

  moderationExtra: Array<ModuleTypesEnum> = [
    ModuleTypesEnum.AlbumLimitHandlerModule,
    ModuleTypesEnum.CloseChatHandlerModule,
    ModuleTypesEnum.AutocorrectionHandlerModule,
    ModuleTypesEnum.NewUsersAttackHandlerModule,
    ModuleTypesEnum.EditMediaHandlerModule,
    ModuleTypesEnum.NsfwHandlerModule,
    ModuleTypesEnum.BotGuardHandlerModule
  ]

  // get lowerThenMinOrHigherThenHour() {
  //   return this.autoAttackModeInitValue && ( this.autoAttackModeInitValue > 3600 || this.autoAttackModeInitValue < 60)
  // }
  //
  // get from(): string {
  //   return this.$store.state.chatState.chat.config.close_chat_interval.from
  // }
  //
  // set from(fromValue: string) {
  //   this.$store.commit('pi/EXEC', {
  //     'fn': () => {
  //       this.$store.state.chatState.chat.config.close_chat_interval.from = fromValue
  //     }
  //   })
  // }
  //
  // get to(): string {
  //   return this.$store.state.chatState.chat.config.close_chat_interval.to
  // }
  //
  // set to(toValue: string) {
  //   this.$store.commit('pi/EXEC', {
  //     'fn': () => {
  //       this.$store.state.chatState.chat.config.close_chat_interval.to = toValue
  //     }
  //   })
  // }
  //
  // closeChatBanTypeOptions() {
  //   return new Promise(resolve => resolve([
  //     { label: this.$t('close_chat_type_all'), value: 'All' },
  //     { label: this.$t('close_chat_type_media'), value: 'Media' },
  //   ]))
  // }
  //
  // nsfwBanTypeOptions() {
  //   return new Promise(resolve => resolve([
  //     { label: this.$t('nsfw_ban_type_none'), value: 'None' },
  //     { label: this.$t('nsfw_ban_type_ban'), value: 'Ban' },
  //     { label: this.$t('nsfw_ban_type_kick'), value: 'Kick' },
  //     { label: this.$t('nsfw_ban_type_mute'), value: 'Mute' },
  //     { label: this.$t('nsfw_ban_type_mute_media'), value: 'MuteMedia' },
  //   ]))
  // }
  //
  mounted() {
    // this.autoAttackModeInitValue = this.stateChatConfigPath.auto_attack_mode_interval
    this.$baseTemplate.saveButton.hide()
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"moderation-extra"},[(_vm.currentView === 0)?[_c('page-title',{attrs:{"subtitle":null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}],null,false,4046080315)}),(_vm.$store.getters.isChatSet)?[_c('a-card',[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'remove_system_pin_message',
              'hasAnchor': true,
            }
          }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'remove_system_voice_chat_messages' ,
              'hasAnchor': true,
            }
          }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'remove_system_boost_messages',
              'hasAnchor': true,
            }
          }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('config-field',{attrs:{"slot":"title","title":_vm.$t('filters_extra_settings_title'),"help-message":_vm.$t('filters_extra_settings_help_message')},slot:"title"}),_c('div',{staticClass:"flex flex-col md:flex-row md:gap-5"},[_c('time-unit-input',{staticClass:"mt-0 w-full full-width-time-unit-select",attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'ban_time_1',
                'step': _vm.StepUnit.Minute,
                'hasAnchor': true,
              }
            }}}),_c('time-unit-input',{staticClass:"mt-0 w-full full-width-time-unit-select",attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'ban_time_2',
                'step': _vm.StepUnit.Minute,
                'hasAnchor': true,
              }
            }}})],1),_c('div',{staticClass:"flex flex-col md:flex-row md:gap-5"},[_c('time-unit-input',{staticClass:"w-full full-width-time-unit-select",attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'ban_time_3',
                'step': _vm.StepUnit.Minute,
                'hasAnchor': true,
              }
            }}}),_c('time-unit-input',{staticClass:"w-full full-width-time-unit-select",attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'ban_time_4',
                'step': _vm.StepUnit.Minute,
                'hasAnchor': true,
              }
            }}})],1),_c('time-unit-input',{staticClass:"mb-0 full-width-time-unit-select",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'ban_time_5',
              'step': _vm.StepUnit.Minute,
              'hasAnchor': true,
            }
          }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_manual_moderation_extra_settings_title')}},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'remove_bots_commands',
              'hasAnchor': true,
            }
          }}}),(_vm.$store.getters.isRuChat)?_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'enable_command_aliases',
              'hasAnchor': true,
            }
          }}}):_vm._e()],1)]:_c('a-spin',{staticClass:"w-full text-center"})]:_vm._e(),_c('modules-screens-wrapper',{attrs:{"module-types-list":_vm.moderationExtra},on:{"current-view-update":function($event){_vm.currentView = $event}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }












































































































































import ModuleCard from './ModuleCard.vue'
import ModuleBuilder from '@/includes/logic/Modules/Builders/ModuleBuilder'
import { groupsSelectOptions } from "@/includes/logic/Modules/ModulesGroupsUtil";
import { ModuleGroupEnum } from "@/includes/logic/Modules/types/types";
import ModulesListMixin from "@/includes/logic/Modules/mixins/ModulesListMixin";

import AddEntityCard from 'piramis-base-components/src/components/AddEntityCard.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import SearchTextInput from 'piramis-base-components/src/components/Pi/fields/SearchTextInput/SearchTextInput.vue';
import { FieldData } from "piramis-base-components/src/components/Pi/types";
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue';
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import Vue from "vue";

@Component({
  components: {
    HelpMessage,
    PiTabs,
    ModuleCard,
    AddEntityCard,
    EmptyData,
    SearchTextInput
  }
})
export default class ActiveModulesList extends Mixins(ModulesListMixin) {
  @Prop({ type: Array, required: true }) modules!: Array<ModuleBuilder>

  @Prop({ type: Boolean, default: false }) showGroup!: boolean

  @Prop({ type: Boolean, default: false }) hasTabs!: boolean

  @Prop({ type: Boolean, default: false }) hasSearch!: boolean

  @Prop({ type: Boolean, default: true }) hasActions!: boolean

  @Prop({ type: Boolean, default: true }) canAdd!: boolean

  @Prop({ type: String, required: false }) updatedModuleGuid!: string | undefined | null

  @Emit()
  onModuleRemoveClick(module: ModuleBuilder) {
    return module
  }

  @Emit()
  onAddModuleClick() {
    return {
      params: {
        tab: this.currentGroup ?? null
      }
    }
  }

  @Emit()
  onModuleSettingClick(module: ModuleBuilder) {
    return module.model
  }

  @Emit()
  clearUpdatedModuleGuid() {
    return null
  }

  get queriedActiveModules() {
    if (this.modules.length) {
      return this.queriedModules(this.modules)
    }

    return []
  }

  get currentModulesGroups() {
    return groupsSelectOptions.filter(g => this.modules.some(m => m.group === g.value as ModuleGroupEnum))
  }

  modelSetter(args: FieldData) {
    args.setter = (value: string) => {
      args.model[args.key] = value.trim()
    }

    return args
  }

  mounted() {
    this.$nextTick(() => {
      if (this.updatedModuleGuid) {
        const moduleCardRefs = this.$refs[this.updatedModuleGuid]

        if (Array.isArray(moduleCardRefs) && moduleCardRefs.length) {
          const cardEl = moduleCardRefs[0] as Vue
          const classNames = [ 'border', 'border-solid', 'border-red-400' ]

          cardEl.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          cardEl.$el.classList.add(...classNames)

          this.clearUpdatedModuleGuid()

          setTimeout(() => {
            cardEl.$el.classList.remove(...classNames)
          }, 3000)
        }
      }
    })
  }
}
